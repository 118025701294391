<template>
  <v-form autocomplete="off" @submit.prevent="$emit('submit')">
    <v-text-field :value="news.title" label="Заголовок" @input="setField('title', $event)" />

    <wysiwyg-editor :value="news.text" @input="setField('text', $event)">
      <template v-slot:title>Текст статьи</template>
    </wysiwyg-editor>

    <div class="mt-4 text-right">
      <v-btn type="submit" :loading="loading" color="accent">Сохранить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    news: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
};
</script>
